import React from "react";
import { graphql } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import { constructActiveDoc } from "../utilities/helpers";
import Layout from "../components/common/Layout";

import Anchor from "../components/elements/Anchor";

const NotFoundPage = ({ data }) => {
  if (!data) return null;
  // console.log(data);

  const {
    fof_title: title,
    fof_description: description,
    fof_image: image,
    fof_image_mobile: imageMobile,
    fof_link: link,
    fof_link_label: linkLabel,
  } = data.settings.data;

  return (
    <div className="page-404">
      <Layout activeDocMeta={constructActiveDoc(data)}>
        <Container className="py-6 text-center my-5 wrapper-c">
          <Row className="mt-5">
            {/* <Col lg={{ span: 6, offset: 3 }}>
            {image.gatsbyImageData && (
              <GatsbyImage
                image={image.gatsbyImageData}
                alt={image.alt}
                className={
                  imageMobile?.gatsbyImageData ? "d-none d-md-block" : null
                }
              />
            )}
            {imageMobile.gatsbyImageData && (
              <GatsbyImage
                image={imageMobile.gatsbyImageData}
                alt={imageMobile.alt}
                className="d-md-none"
              />
            )}
          </Col> */}
            <Col sm={12}>
              <h1>{title}</h1>
              <p>{description}</p>

              <div className="book-now-outline mx-auto mt-5 mona">
                <Anchor href={link.url} className="book-now-btn text-center">
                  {linkLabel}
                </Anchor>
              </div>
            </Col>
          </Row>
        </Container>
      </Layout>
      <div />
    </div>
  );
};

export const query = graphql`
  query notFoundQuery($lang: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
  }
`;

// export const query = graphql`
//   query aboutQuery($lang: String, $id: String) {
//     settings: prismicSettings(lang: { eq: $lang }) {
//       ...prismicSettingsFragment
//     }
//     page: prismicAbout(lang: { eq: $lang }, id: { eq: $id }) {
//       ...prismicAboutFragment
//     }
//   }
// `;

// export const query = graphql`
//   query notFoundPageQuery($lang: String) {
//     prismicSettings(lang: { eq: $lang }) {
//       ...prismicSettingsFragment
//     }
//   }
// `;

export default NotFoundPage;
